import styled from 'styled-components'
import { Popover as PopoverLib } from 'antd'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  padding-left: 2px;
  right: 0;
  color: ${({ theme }) => theme.colors.gray};
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
`

export const Popover = styled(PopoverLib)``

export const PopoverContainer = styled.div`
  max-width: 200px;
  font-size: 1rem;
`
